.header {
  background-color: #31353d;
  padding: 10px 5px 10px 10px;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-light {
  background-color: #31353d;
  color: #fcfbfb;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-light
  .ant-menu-item-selected {
  color: #fcbcb2 !important;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-active::after,
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom-color: #fcbcb2 !important;
}
:where(.css-dev-only-do-not-override-1m62vyb).ant-menu-horizontal {
  line-height: 35px;
}

.alert1 {
  background: #fcbcb2;
  text-align: center;
  font-weight: bold;
  padding: 3px;
  letter-spacing: 2px;
  word-spacing: 6px;
  font-size: 12px;
  color: #31353d;
}
.header-img {
  background-image: url(./Images/header\ image.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: start;
  padding: 40px 30px;
}
.s1-img {
  background-image: url(./Images/text\ scroll\ image.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: start;
  padding: 40px 30px;
}
.s2-box1 {
  background-image: url(./Images/bg\ image1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 0;
}
.s2-box2 {
  background-image: url(./Images/bg\ image2.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 0;
}
.s2-img1 {
  background-color: #fcbcb2;
  padding: 21px;
  opacity: 0.8;
}
.s2-img2 {
  background-color: #31353d;
  padding: 21px;
  opacity: 0.8;
}
.s1-box {
  background-color: #31353d;
  padding: 20px;
  opacity: 0.7;
}
.s1-img-t0 {
  color: #fcbcb2;
  font-weight: bold;
  font-size: 14px;
  word-spacing: 2px;
  margin-bottom: 5px;
}
.s1-img-t1 {
  color: #fcfbfb;
  font-weight: bold;
  font-size: 12px;
  word-spacing: 2px;
  margin-bottom: 6px;
}

.s1-img-t2 {
  color: #fcfbfb;
  font-size: 8px;
  letter-spacing: 1px;
  margin-bottom: 3px;
}
.s2-img1-t0 {
  color: #31353d;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.s2-img2-t0 {
  color: #fcfbfb;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.t1 {
  color: #fcfbfb;
  font-weight: bold;
  font-size: 18px;
  word-spacing: 2px;
  margin-bottom: 3px;
}

.t2 {
  color: #fcfbfb;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 3px;
}
.comming-soon-btn {
  background: transparent;
  border: 1px solid #fcbcb2;
  color: #fcbcb2;
  letter-spacing: 2px;
  word-spacing: 2px;
  font-size: 7px;
  margin-top: 8px;
  padding: 6px 10px;
  /* width: 168px;
  padding: 1vw;
  background: transparent;
  border: 1px solid #fcbcb2;
  color: white; */
}
.shopnow1-btn {
  background: #31353d;
  color: #fcfbfb;
  border: none;
  letter-spacing: 1px;
  word-spacing: 1px;
  font-size: 7px;
  margin-top: 7px;
  padding: 6px 15px;
  /* cursor: pointer; */
}

.shopnow2-btn {
  background: #fcbcb2;
  color: #31353d;
  border: none;
  letter-spacing: 1px;
  word-spacing: 1px;
  font-size: 7px;
  margin-top: 7px;
  padding: 6px 15px;
}
.s3 {
  background-color: #fcfbfb;
  color: #31353d;
  padding: 20px;
  text-align: center;
  font-size: 16px;
}
.footer {
  background-color: #31353d;
  padding: 20px 0;
  color: #fcfbfb;
  line-height: 20px;
}
.line1 {
  height: 2px;
  width: 100vw;
  background-color: #fcbcb2;
}
.line2 {
  height: 2px;
  width: 50vw;
  margin: 0 auto;
  background-color: #726969;
}
.center {
  text-align: center;
}
.contact-us-txt {
  color: #fcfbfb;
  padding: 10px 6px;
  font-size: 18px;
}
.contact-link {
  color: #fcbcb2 !important;
}
.footer-txt {
  font-size: 14px;
}
.m30 {
  margin-bottom: 30px;
}
@media (min-width: 480px) {
  .header-img {
    padding: 70px 40px;
  }
  .t1 {
    font-size: 25px;
  }

  .t2 {
    font-size: 15px;
  }
  .s1-img-t0 {
    font-size: 18px;
  }
  .s1-img-t1 {
    font-size: 16px;
  }

  .s1-img-t2 {
    font-size: 13px;
  }
  .s1-box {
    padding: 30px;
  }
  .s2-box1 {
    padding: 70px 0;
  }
  .s2-box2 {
    padding: 70px 0;
  }
  .s2-img2 {
    padding: 50px;
  }
  .s2-img1 {
    padding: 50px;
  }
  .shopnow2-btn {
    font-size: 12px;
  }
  .shopnow1-btn {
    font-size: 12px;
  }
  .s2-img2-t0 {
    font-size: 20px;
  }
  .s2-img1-t0 {
    font-size: 20px;
  }
  .comming-soon-btn {
    font-size: 10px;
    margin-top: 11px;
    padding: 9px 20px;
  }
  .s3 {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  .alert1 {
    padding: 6px;
    font-size: 16px;
  }
  .header-img {
    padding: 100px 40px;
  }
  .t1 {
    font-size: 40px;
  }

  .t2 {
    font-size: 20px;
  }
  .s1-img-t0 {
    font-size: 26px;
  }
  .s1-img-t1 {
    font-size: 25px;
  }

  .s1-img-t2 {
    font-size: 19px;
  }
  .comming-soon-btn {
    font-size: 13px;
    margin-top: 19px;
    padding: 9px 20px;
  }
  .s1-box {
    padding: 50px;
  }
  .s2-box1 {
    padding: 100px 0;
  }
  .s2-box2 {
    padding: 100px 0;
  }
  .s2-img2 {
    padding: 120px 80px;
  }
  .s2-img1 {
    padding: 120px 80px;
  }
  .shopnow2-btn {
    font-size: 20px;
  }
  .shopnow1-btn {
    font-size: 20px;
  }
  .s2-img2-t0 {
    font-size: 30px;
  }
  .s2-img1-t0 {
    font-size: 30px;
  }
  .s3 {
    font-size: 20px;
  }
}
/* .slider{
  display: flex;
  justify-content: flex-start;
  width: 100%;
} */
.abc{
  padding: 10px;
}
/* ----------------- */
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
font-size: inherit;
color: currentColor;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
left: 10px;
font-size: 27px;
z-index: 2;
color: #fcbcb2;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
right: 10px;
font-size: 27px;
z-index: 2;
color: #fcbcb2;
}
